const IImageTextLinkSchema = {
    title: 'ImageTextLink',
  
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: [
          'hidden',
          'layout',
          'title',
          'text',
          'image',
          'flip_image',
          'text_right',
          'cta',
          'target_url'
        ],
      },
    ],
  
    properties: {
      hidden: {
        title: 'Hide this component',
        description: 'Does not render the component',
        type: 'boolean',
      },

      layout: {
        title: 'Card Layout',
        description: 'Component layout',
        type: 'choice',
        choices: [['large', 'Large'], ['medium', 'Medium'], ['small', 'Small']]
      },

      title: {
        title: 'Title',
        description: 'Title',
        widget: 'title',
      },

      text: {
        title: 'Text',
        description: 'Text',
        widget: 'text',
      },

      image: {
        title: 'Portrait Image',
        description: 'Choose Portrait Image',
        widget: 'object_browser',
        selectableTypes : ['Image'], 
        mode: 'link'
      },


      flip_image: {
        title: 'Flip Image Horizontally',
        description: 'Flip Image Horizontally',
        type: 'boolean',
      },

      text_right: {
        title: 'Text Right',
        description: 'Align text right, image left',
        type: 'boolean',
      },

      cta: {
        title: 'Call to Action',
        description: 'Call to Action / Link Text',
        widget: 'text',
      },

      target_url: {
        title: 'Target URL',
        description: 'Choose Target Page for Link',
        widget: 'object_browser',
        selectableTypes : ['page'], 
        mode: 'link'
      },
      
    },
  
    required: ['image', 'text'],
  };
  
  export default IImageTextLinkSchema;
import React, { useEffect, useRef, useState }  from 'react';
import './cta.less';


const CTAView = (props) => {
  let ref = useRef();
  
  const firstText = props.data.firstText;
  const secondText = props.data.secondText;
  const link = props.data.link;
  const linkText = props.data.linkText;
  const url = (link!=null && link!=undefined && link.length > 0) ? link[0]["getURL"].replace(process.env.RAZZLE_API_PATH, "") : "";

  // From Bug Detected Recently
  const cleanUrl = url.replace("https://gliscomedia.com", "");

  const blockClass = "call-to-action";
  const firstTextClass = "cta-text first-text";
  const secondTextClass = "cta-text second-text";

  
  return( <div className={blockClass}>
          <div className={firstTextClass}>{firstText}</div>
          <div className={secondTextClass}>{secondText}</div>
          <a className='cta-link' href={cleanUrl}>{linkText}</a>
         </div>)
};

export default CTAView;
import React, { useEffect, useRef, useState }  from 'react';
import { useSelector } from 'react-redux';

import { isBrowser} from '../utilities';
import './image-ticker-styles.less';

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
ScrollTrigger.defaults({
  // markers: true,
  scrub: true,             
  // toggleActions: "play pause reverse pause",
  toggleActions: "play none none none",
  // play, pause, resume, reverse, restart, reset, complete, none
  // 4 values set to pastStartPoint pastEndPoint reEnter scrollBackPastTheStart
})

const [UNLOADED, LOADING, INIT, RUNNING, PAUSED, STOPPED, OBSERVING] = [-2, -1,0,1,2,3,4];

const ImageTickerView = (props) => {

    const editEnabled = (props.data.editEnabled==true) ? true : false;  
    const shouldShow = (props.data.hidden==true) ? false : true;

    let ref = useRef();
    let carrousel = useRef();
    const tickerId = useRef(`ImageTicker-${Math.floor(Math.random() * 10000)}`);
    const timelinesAdded = useRef([]);
    const tickerRefs = useRef([]);
    const loaded = useSelector((state) => { return state.content.get.loaded });

    const [overalContainerStatus, setOverallContainerStatus] = useState(UNLOADED);
    const [imageSizes, setImageSizes] = useState({});

    const pendingImagesObject = useSelector((state) => { return state.pendingImages });
    const tickerReady = () => {return (pendingImagesObject.pending==0 && pendingImagesObject.allLoaded==true)}

    const containerClass = shouldShow || editEnabled ? `image-ticker-container` : `image-ticker-container hidden`;


    useEffect (() => {
      
        if (isBrowser() && overalContainerStatus==INIT) {   
          if (shouldShow && !editEnabled) {            
            if (timelinesAdded.current.indexOf(tickerId.current) == -1) {
              const sizesData = JSON.parse(atob(imageSizes["base64"]));
              const totalSize = Object.values(sizesData).reduce((prev, curr) => prev + curr.width + 100, 0);
              const widthDisplacement = totalSize - document.body.clientWidth;
                // console.log(`>>>>.....>>>>> ImageTickerView pendingImages is ${pendingImages} and loaded is ${loaded} and isBrowser is ${isBrowser()} and overalContainerStatus is ${overalContainerStatus}`);
              // console.log(`widths are scrollWidth: ${ref.current.scrollWidth}, carrouselWidth: ${carrousel.current.clientWidth}, bodyWidth: ${document.body.clientWidth} offsetWidth: ${ref.current.offsetWidth} and clientWidth: ${ref.current.clientWidth} clientHeight: ${ref.current.clientHeight} and scrollWidth: ${ref.current.scrollWidth}`);
              // console.log(`imageSizes ${totalSize} is \n\n`, JSON.stringify(sizesData, null, 2));
              // const scrollWidth = 1*(ref.current.scrollWidth-document.body.clientWidth);
              const scrollWidth = widthDisplacement > 0 ? -widthDisplacement : widthDisplacement;
              // const scrollWidth = - ref.current.offsetWidth;
              const ctx = gsap.context(() => {
                gsap.timeline()
                  .addLabel("ImageTickerViewScrollController")
                  .to(".image-ticker-carrousel", {
                    marginLeft: scrollWidth,
                    scrollTrigger: {
                      markers: false,
                      id: tickerId.current,
                      trigger: '.image-ticker-scroller span',
                      start: `top 50%`,
                      end: (self) => `bottom top`,
                      onEnter: (self) => {
                        // setScrollerControlRunning(RUNNING);
                        // console.log("ENTERED scrollController");
                      },
                
                      // onLeave: (self) => {
                      //   console.log("LEFT scrollController");
                      // },
          
                    //   onLeaveBack: (self) => {
                    //     // console.log("LEAVE BACK scrollController");
                    //     setScrollerControlRunning(OBSERVING);
                    //     // setReleaseFixedTextRunning(RUNNING);
                    //     // setContainerFixRunning(INIT);
                    //   }
                    }
                  }); 
          
                return () => {
                  // console.log(`Image With Text Cleanup Called`);
                };
            
              }, ref); 
    
              timelinesAdded.current.push(tickerId.current);
            } 

            setOverallContainerStatus(RUNNING);
    
          } 
        }
    
      }, [overalContainerStatus])

    const handleLoad = () => {
        // console.log("+++++++++++++++++++ ======================= handleLoad CALLED. Loaded = " + loaded + " pendingImages = " + pendingImages + " tickerReady = " + tickerReady());
        if (tickerReady()) {
          
          const sizesObj = {};
          // console.log(`>>>>> handleLoad imageSizes is empty. tickerRefs are: `, tickerRefs.current);
          tickerRefs.current.forEach((ref) => {
            try {
              const imageElement = document.getElementById(ref);
              const imageProportion = imageElement.naturalWidth / imageElement.naturalHeight;
              const [MAX_WIDTH, MAX_HEIGHT] = [300, 150]
              const [width, height] = (imageProportion > 1) ? [MAX_WIDTH, MAX_WIDTH / imageProportion] : [MAX_HEIGHT * imageProportion, MAX_HEIGHT];
              if (imageProportion > 1) {
                imageElement.classList.add("image-ticker-image-landscape");

              } else {
                imageElement.classList.add("image-ticker-image-portrait");
              }

              // console.log(ref, imageElement.naturalWidth, imageElement.naturalHeight, imageElement.clientWidth, imageElement.clientHeight, width, height);

              // imageElement.classList.add("image-ticker-image");

              sizesObj[imageElement.id] = {
                width: width,
                height: height,
              }

            } catch (e) {
              console.log(`Error in tickerRefs.current.forEach: ${e}`);
            }
            // document.getElementById(ref).classList.add("image-ticker-image");
            // ref.current.classList.add("image-ticker-image");
          });

          const imageStyleDataBase64 = btoa(JSON.stringify(sizesObj) || '')
          
          // console.log(`imageStyleDataBase64 is ${imageStyleDataBase64}`);

          setImageSizes({
            base64: imageStyleDataBase64,
          });

          setOverallContainerStatus(INIT);
        }
    }
    
    useEffect(() => {
        handleLoad();
    }, [loaded]);
    
    // useEffect(() => {
    //   // console.log(`useEffect called. pendingImages = ${pendingImages}`);
    //   handleLoad();
    // }, [pendingImages]);

    useEffect(() => {
      // console.log(`useEffect called. pendingImagesObject = ${pendingImagesObject}`, pendingImagesObject);
      handleLoad();
    }, [pendingImagesObject]);

    const imageStyler = (id) => {
      try {
        const data = JSON.parse(atob(imageSizes["base64"]));
        // console.log(`>>>> styling image ${id}`, data, (id in data));
        return (id in data) ? data[id] : {};
      } catch (e) {
        // console.log(`ERROR imageSizes["base64"]`, imageSizes["base64"]);
        return {};
      }  
      // console.log(`>>>> styling image ${id}`, data, (id in data));
      
    }

    const imageCarrousel = (images) => {
      let items = [];
      try {
        items =  (images!=undefined) ? images.map((image) => {
          const imgUrl = `${image["@id"]}/@@images/${image.image_field}`;
          const imgId = `image-ticker-${image.UID}`
          if (tickerRefs.current.indexOf(imgId) == -1) {
            // console.log(`>>>>> imageCarrousel: ${imgId} added to tickerRefs`, tickerRefs.current); 
            tickerRefs.current.push(imgId);
          }
          return (
              <div className="image-ticker-image" key={imgId} ref={carrousel}>
                  <img id={imgId} src={imgUrl} style={imageStyler(imgId)}/>
              </div>
          )
        }) : [];
      } catch (e) {
        console.log(`Error in imageCarrousel: ${e}`);
        // console.log(props);
      }


      return (
          <div className="image-ticker-carrousel">
              {items}
          </div>
      )  
    }

    useEffect(() => {
      try {
        const data = JSON.parse(atob(imageSizes["base64"]));
        // console.log(`useEffect called. imageSizes = `, imageSizes, data);
      } catch (e) {
        // console.log(`ERROR imageSizes["base64"]`, imageSizes["base64"]);
      }
      
    }, [imageSizes]);

    return (
        <div className={containerClass} ref={ref}>
            <div className='image-ticker-scroller'><span>&nbsp;</span></div>
            {imageCarrousel(props.data.images)}
        </div>    
    )
  
}

export default ImageTickerView;
import React, { useEffect, useRef, useState}  from 'react';
import {useDispatch, useSelector} from 'react-redux';
import './related-content-cards.less';
import {getPages} from '../../../../actions';
import SingleContentCardView from "./SingleContentCardView";
import {toStandardPages, shuffle, isDesktop} from '../utilities';
import {getVocabulary} from '@plone/volto/actions';
import i18nLabels from '../i18n';

const NAVIGATION_VECTORS = 5;


const RelatedContentCardsView = (props) => {
  const MINIMUM_VECTORS_DISPLAYED=3; // If fewer, resorts to carrousel view
  const ref = useRef();
  const cardsRef = useRef();
  const scrollable = useRef();
  const inline = useRef({});
  const positionRef = useRef(0);
  const activationStateForEvents = useRef("inactive");

  const defaultLocale = useSelector((state) => state.intl.defaultLocale);
  const locale = useSelector((state) => state.intl.locale);
  const localeToUse = (locale!=null && locale!=undefined && locale.trim().length>0) ? locale : defaultLocale;

  const translator = (lang, key) => {
    return (i18nLabels[lang][key]!=undefined) ? i18nLabels[lang][key] : i18nLabels["en"][key];
  }
  
  const [sectionGroupings, setSectionGroupings] = useState([]);
  const [tagBasedPages, setTagBasedPages] = useState([]);
  const [sectionPages, setSectionPages] = useState([]);
  const [forceCarrousel, setForceCarrousel] = useState(true);
  // const [vocabularyItems, setVovabularyItems] = useState(null);
  // const [isActivated, setIsActivated] = useState("inactive");
  const [leftNavigationState, setLeftNavigationState] = useState("nav-button inactive");
  const [rightNavigationState, setRightNavigationState] = useState("nav-button inactive");
  const [scrollPosition, setScrollPosition] = useState(0);
  const [containerWidth, setContainerWidth] = useState(0);

  const [loadTimer, setLoadTimer] = useState(0);

  let selector = props.data.selector;
  let titleClass = (props.data.reverseTitle==true) ? "darkbg" : "lightbg";
  let carrousel = (props.data.carrousel==true) ? true : false;
  let groupByTaxonomy = (props.data.groupByTaxonomy==undefined || props.data.groupByTaxonomy==null || props.data.groupByTaxonomy.trim().length==0) ? undefined : props.data.groupByTaxonomy;
  let maxItems = (isNaN(parseInt(props.data.maxItems))) ? 4 : parseInt(props.data.maxItems);
  const title = (props.data.title!=undefined && props.data.title.length>0) ? props.data.title : translator(localeToUse,"title");

  const market = (props.properties.fsegments!=undefined && props.properties.fsegments.length>0) ? props.properties.fsegments[0].title : "Segment";
  const marketToken = (props.properties.fsegments!=undefined && props.properties.fsegments.length>0) ? props.properties.fsegments[0].token : "Segment";

  const genre =  (props.properties.fgenres!=undefined && props.properties.fgenres.length>0) ? props.properties.fgenres[0].title : "this genre";
  const genreToken =  (props.properties.fgenres!=undefined && props.properties.fgenres.length>0) ? props.properties.fgenres[0].token : "this genre";

  const structure = (props.properties.fstructures!=undefined && props.properties.fstructures.length>0) ? props.properties.fstructures[0].title : "this structure";
  const structureToken = (props.properties.fstructures!=undefined && props.properties.fstructures.length>0) ? props.properties.fstructures[0].token : "this structure";

  const composition = (props.properties.fcompositions!=undefined && props.properties.fcompositions.length>0) ? props.properties.fcompositions[0].title : "Composition";
  const compositionToken = (props.properties.fcompositions!=undefined && props.properties.fcompositions.length>0) ? props.properties.fcompositions[0].token : "Composition";

  const addons = (props.properties.faddons!=undefined && props.properties.faddons.length>0) ? props.properties.faddons[0].title : "Decorative";
  const addonsToken = (props.properties.faddons!=undefined && props.properties.faddons.length>0) ? props.properties.faddons[0].token : "Decorative";
  const blockClass = "related-content-cards";

  const resetNavigation = () => {
    try{
      const node = scrollable.current;
      const childrenWidth = Array.from(node.querySelectorAll("div.relatedContentCard"))
                                    .reduce((widthSum, currentNode) => {
                                      const width = (currentNode.dataset.width != undefined) ? parseInt(currentNode.dataset.width) : 0;
                                      return widthSum + width;
                                    }, 0);
      console.log(`RelatedContentCards -> resetNavigation -> childrenWidth=${childrenWidth}, scrollPosition: ${scrollPosition}, scrollWidth: ${node.scrollWidth}, clientWidth: ${ref.current.clientWidth}; scrollLeft: ${node.scrollLeft}; scrollRight: ${node.scrollRight}`);

      setLeftNavigationState((scrollPosition==0) ? "nav-button inactive" : "nav-button active");
      setRightNavigationState((childrenWidth + scrollPosition <= ref.current.clientWidth)  ? "nav-button inactive" : "nav-button active");  
    } catch (e) {
      console.log(`RelatedContentCards -> resetNavigation EXCEPTION`, e);
    }
  }

  const handleScrollLeft = (event) => {
    const node = scrollable.current;
    const firstChildPos = node.firstElementChild.getBoundingClientRect().left ; // 20 is the padding
    const correction = (firstChildPos<0) ? firstChildPos-20 : 0;
    const scrollSize = ref.current.clientWidth-node.scrollWidth;
    const scrollDisplacement = scrollPosition-350-correction;
    const scrollMovementR = (scrollSize < 0 && scrollDisplacement < 0) ? Math.min(scrollSize, scrollDisplacement) : Math.max(scrollSize, scrollDisplacement);

    const scrollMovement = Math.min(0, scrollPosition+350);

    setScrollPosition(scrollMovement);
    
  }

  const handleScrollRight = (event) => {
    const node = scrollable.current;
    const firstChildPos = node.firstElementChild.getBoundingClientRect().left ; // 20 is the padding
    const correction = (firstChildPos<0) ? firstChildPos-20 : 0;
    const scrollSize = ref.current.clientWidth-node.scrollWidth;
    const scrollDisplacement = scrollPosition-350-correction;
    const scrollMovement = (scrollSize < 0 && scrollDisplacement < 0) ? Math.min(scrollSize, scrollDisplacement) : Math.max(scrollSize, scrollDisplacement);

    setScrollPosition(scrollMovement);      
  }

  // force execution
  const dispatch = useDispatch();
  const loadedPages = useSelector(state => state.pages);
  const loadedTaxonomies = useSelector(state => state.vocabularies[groupByTaxonomy]);

  const conditionalAllPagesRefresh = () => {
    const FIFTEEN_MINUTES_MILLIES = 15 * 60 * 1000;
    const timelapseSinceLastCall = Date.now() - loadedPages.latestUpdate;
    if ((loadedPages.loaded == false && loadedPages.loading == false) || 
      (loadedPages.loaded == true && timelapseSinceLastCall > FIFTEEN_MINUTES_MILLIES )) {
      console.log(`Dispatching get pages with locale=${localeToUse} and selector=`, selector);
      dispatch(getPages(selector, localeToUse));
    } else {
      console.log(`NOT NOT NOT dispatching get pages with locale=${localeToUse} and selector=`, selector, loadedPages);
    }
  }

  // Used for vector-based related content cards navigation.
  // Essentially creates cards by market, product, material, etc
  const toGroupedByCards = (pages) => {
    try {
      if (loadedTaxonomies!=null && loadedTaxonomies.items!=undefined) {

        const fieldName = `f${groupByTaxonomy.split(".").slice(-1)[0]}`;
  
        const group = loadedTaxonomies.items.map((v) => {
          try {
            const inclusionCandidates = loadedPages.pages.filter((p) => {
              try {
                const tokens = p[fieldName].map((c)=>c.token);
                const includes = tokens.includes(v.value) && (p.language.token==localeToUse || p.language.token == undefined || p.language.token.length==0);
                return includes;
              } catch (e) {
                return false;
              }
            });
            const mappedObject = {
                token: v.value,
                title: v.label,
                pages: [...inclusionCandidates],
                total: inclusionCandidates.length
              }

              return mappedObject; 
          } catch (e) {
            console.log(e);
            return null;  
          }
        }).filter((g) => g.total>0).sort((a,b) => {
          if ( a.title < b.title ){
            return -1;
          }
          if ( a.title > b.title ){
            return 1;
          }
          return 0;
        }); 

        setSectionGroupings(group);
      }
    } catch (e) {
      console.log("!!!!!!!!! ERROR in toGroupedByCards");
      console.log(e);
    }
    
  
  }

  const setCardsContent = () => {
    try {
      const startTimestamp = Date.now();
      const cardWidth = (document==undefined) ? 350 : scrollRevealingWidth(350+20, NAVIGATION_VECTORS); // width(350) + margins (20+20)
  
      inline.current = {
        width: cardWidth,
        minWidth: cardWidth,
        maxWidth: cardWidth
      }

      const seeAlsoBySegment = findSimilarPagesByTaxonomy("fsegments", loadedPages.pages);
      const seeAlsoByGenre = findSimilarPagesByTaxonomy("fgenres", loadedPages.pages);
      const seeAlsoByStructure = findSimilarPagesByTaxonomy("fstructures", loadedPages.pages);
      const seeAlsoByComposition = findSimilarPagesByTaxonomy("fcompositions", loadedPages.pages);
      const seeAlsoByAddon = findSimilarPagesByTaxonomy("faddonss", loadedPages.pages);
    
      const sections = [
        {
          title: market,
          token: marketToken,
          // filter: fashionProductSegments,
          cards: productCardsFromPages(seeAlsoBySegment)
        },
        
        {
          title: genre,
          token: genreToken,
          cards: productCardsFromPages(seeAlsoByGenre)
        },
    
        {
          title: structure,
          token: structureToken,
          cards: productCardsFromPages(seeAlsoByStructure)
        },
    
        {
          title: composition,
          token: compositionToken,
          cards: productCardsFromPages(seeAlsoByComposition)
        },
    
        {
          title: addons,
          token: addonsToken,
          cards: productCardsFromPages(seeAlsoByAddon)
        },
      ];
    
      const eligibleSections = sections.filter((s) => s.cards.length > 0);

      const tagBasedRelatedPages = toStandardPages(loadedPages.pages);
      
      const _forceCarrousel = eligibleSections.length < MINIMUM_VECTORS_DISPLAYED;

      const allVectorPages = [seeAlsoBySegment, seeAlsoByGenre,seeAlsoByStructure, seeAlsoByComposition, seeAlsoByAddon].flat();
      const uniquePages = [...new Set(allVectorPages.map(p => p.UID))];
      const cardsToShow = (_forceCarrousel) ? toStandardPages(uniquePages.map((uid) => allVectorPages.filter((p) => p.UID==uid)[0])) : tagBasedRelatedPages;

      toGroupedByCards(loadedPages.pages);
      setForceCarrousel(_forceCarrousel);
      setTagBasedPages(cardsToShow);
      setSectionPages(eligibleSections);

      const finishTimestamp = Date.now();
    } catch (e) {
      console.log(e);
    }
  
    resetNavigation();
  }

  // useEffect(() => {
  //   resetNavigation();
  // }, [isActivated]);

  useEffect(() => {
    scrollable.current != undefined && setContainerWidth(scrollable.current.scrollWidth) ;
    resetNavigation();
  }, [scrollPosition]);

  useEffect(() => {
    setContainerWidth(ref.current.clientWidth);
    const node = scrollable.current;
    setLoadTimer(Date.now());
    console.log(`IN RELATED CONTENT CARDS VIEW: loadedPages is `, loadedPages);

    // dispatch(getPages(selector, localeToUse));
    conditionalAllPagesRefresh();
    if (groupByTaxonomy!=undefined) {
      dispatch(getVocabulary({vocabNameOrURL:groupByTaxonomy}));
    }
    setCardsContent();
  }, []);

  useEffect(() => {
    console.log(`useEffect[loadedPages] triggered. Last updated on ${loadedPages.latestUpdate} with success=${loadedPages.success} and searchString=${loadedPages.searchString}`, loadedPages);
    setCardsContent();

  }, [loadedPages]);

  useEffect(() => {
    
    toGroupedByCards(loadedPages.pages);
    resetNavigation();
  }, [loadedTaxonomies])

  const findSimilarPagesByTaxonomy = (filterTaxonomy, pages) => {
    const contextSegments = (props.properties[filterTaxonomy]!=null && props.properties[filterTaxonomy]!=undefined) ? props.properties[filterTaxonomy].map(s => s.token) : [];
    const contextMainSegment = (contextSegments.length > 0) ? contextSegments[0] : null;

    const seeAlso = pages.filter((page) => {
      const pageSegments = (page[filterTaxonomy]!=null && page[filterTaxonomy]!=undefined) ? page[filterTaxonomy].map(s => s.token) : [];
      const segString = pageSegments.join(";");
      const include = pageSegments.includes(contextMainSegment) && page.UID != props.properties.UID && (page.language.token==localeToUse || page.language.token == undefined || page.language.token.length==0);
      return include;
    });

    return shuffle(seeAlso).slice(0, maxItems-1);
  }

  const productCardsFromPages = (pages) => {
    return pages.filter((page) => page.language.token==localeToUse || page.language.token == undefined || page.language.token.length==0).map((page) => {
      const imgUrl = (page.preview_image!=null & page.preview_image!=undefined) ? page.preview_image.download.replace(process.env.RAZZLE_API_PATH, "") : "";
      return (
        <SingleContentCardView 
              uid={page.UID}
              key={page.UID}
              fullCardLayout={false} 
              cardWidth={350} 
              title={page.title} 
              description={page.description} 
              image={imgUrl} 
              link={page["@id"].replace(process.env.RAZZLE_API_PATH, "")}/>      
      )
    })
  }

  const getSearchUrl = (taxonomy, token) => {
    try {
      const field = taxonomy.split(".").slice(-1)[0].toLowerCase();
      switch (field) {
        case 'genres':
          return `fashionProductGenres=${token}`;
        case 'structures':
          return `fashionProductStructures=${token}`;
        case 'segments':
          return `fashionProductSegments=${token}`;
        case 'compositions':
          return `fashionProductCompositions=${token}`;
        case 'addons':
          return `fashionProductAddons=${token}`;
        default:
          return ""
      }
    } catch (e) {
      return ""
    }
    
  }

  const sectionCardsFromPages = (groupings) => {

    return groupings.map((group) => {
      const imgSrc = (group.pages[0].preview_image != null) ? group.pages[0].preview_image.scales.preview.download : "";
      const dynamicDescription = (group.total == 1) ? group.pages[0].description : translator(localeToUse, "seeSelection");
      const url = (group.total == 1) ? group.pages[0]["@id"].replace(process.env.RAZZLE_API_PATH, "") : `/search?${getSearchUrl(groupByTaxonomy, group.token)}&Language=${localeToUse}`;
      const gtitle = translateLabel(group.token, group.title);

      return (
        <SingleContentCardView 
              key={group.token}
              uid={group.token}
              fullCardLayout={true} 
              cardWidth={350} 
              title={gtitle} 
              description={dynamicDescription} 
              image={imgSrc} 
              link={url}
              totals={group.total}/> 
      );
    });
  }

  const showCategoryGroupings = () => {
    return groupByTaxonomy!=undefined;
  }

  const [widths, setWidths] = React.useState({
    totalWidth: 0,
    displayedWidth: 0
  });

  const calculateNumberOfPages = () => {

    var totalWidth = widths.totalWidth;
    var displayedWidth = widths.displayedWidth;
    var numberOfPages = Math.floor(totalWidth / displayedWidth);

    var list = [];
    for(var i = 0; i < numberOfPages; i++){
      list.push(i);
    }
    return list;
    }

  const navButtonsRender = () => calculateNumberOfPages().map((index) =>
        <div className='related-nav-button' page-number={index} onClick={scrollPage}></div>
      );

  const [navState, setNavState] = React.useState({ 
    navButtons: navButtonsRender(),
  });


  const navButtonSetter = () => {
    if ((carrousel || forceCarrousel) && !showCategoryGroupings()) {
      /*
      focus: tagBasedPages
                data-total-cards={tagBasedPages.length} 
                 data-cards-loaded={loadedPages.pages.length} 
      */
    } else if (!carrousel && !forceCarrousel && !showCategoryGroupings()) {
      /*
      focus: sectionPages
        */
    } else if (showCategoryGroupings()) {
      /*
      focus: sectionGroupings
        */
    }

  }

  function scrollPage(pageNumber) {

    var scrollTarget = event.target.parentElement.previousElemtentSibling;

    var displayedWidth = scrollTarget.clientWidth;
    var widthToScroll = displayedWidth * pageNumber;
  
    scrollTarget.scrollLeft = widthToScroll;

    var element = document.getElementById(pageNumber);
    element.scrollIntoView({behavior: 'smooth'});
  }

  const scrollRevealingWidth = (width, totalItems) => {
    const body = document.getElementsByTagName("body")[0];
    const bodyWidth = (document != undefined) ? document.getElementsByTagName("body")[0].offsetWidth : undefined;  
    const visibleItems = bodyWidth/width;

    if (visibleItems>=totalItems) {
      return width;
    } else {
      const adjustedItems = (Math.round(visibleItems) > visibleItems) ? Math.round(visibleItems)-0.5 : Math.round(visibleItems)+0.5;
      const adjustedWidth = bodyWidth/adjustedItems;
      // console.log(`scrollRevealingWidth: bodyWidth: ${bodyWidth}; totalItems=${totalItems};  visibleItems=${visibleItems}; adjustedItems=${adjustedItems} width=${width}; adjustedWidth=${adjustedWidth};  `);
      return adjustedWidth
    }

  }

  const translateLabel = (tagValue, defaultTagTitle, s) => {
    const label = (i18nLabels[localeToUse]!=undefined) ? i18nLabels[localeToUse][tagValue] : undefined;
    const printableLabel = (label!=null && label!=undefined && label.trim().length>0) ? label : defaultTagTitle;
    // console.log(`translateLabel i18nLabels[${localeToUse}][${tagValue}]`, i18nLabels[localeToUse][tagValue], s)
    return printableLabel;
  }  

  return(
    <div className='relatedContentCardsBlock' ref={ref} >
      {(carrousel || forceCarrousel) && !showCategoryGroupings() &&
        <div className="componentContainer">
          <h5 className={titleClass}>{title}</h5>
          <div className={blockClass} ref={cardsRef}>
            <div className='relatedContentContainer tagCards' 
                 data-total-cards={tagBasedPages.length} 
                 data-cards-loaded={loadedPages.pages.length} 
                 ref={scrollable}
                 style={{
                  transform: `translate(${scrollPosition}px,0)`,
                  minWidth: `${containerWidth}px`
                 }}>
              {tagBasedPages.map((p) => SingleContentCardView({
                fullCardLayout: true,
                cardWidth: 350,
                ...p
              }))}
            </div>  
          </div>
          <div className={`relatedContentNav`} >
              <div>{navState.navButtons}</div>
              <div id="scrollLeft" className={leftNavigationState} onClick={handleScrollLeft}>&lt;</div>
              <div id="scrollRight" className={rightNavigationState} onClick={handleScrollRight}>&gt;</div>
          </div>
        </div>  
      }
      {(!carrousel && !forceCarrousel && !showCategoryGroupings() &&
        <div className="componentContainer">
          <div className={blockClass} ref={cardsRef}>
            <div className='relatedContentContainer vectorCards' 
                  ref={scrollable}
                  style={{
                   transform: `translate(${scrollPosition}px,0)`,
                   minWidth: `${containerWidth}px`
                  }}>
              {sectionPages.map((s, index) => {
                return (
                  <div key={index} className='relatedContentSection market' style={inline.current}>
                    <h3>{translator(localeToUse,"moreIn")} <em>{translateLabel(s.token, s.title, s)}</em></h3>
                    <div className='cardsContainer'>
                      {s.cards}
                    </div>
                  </div>
                )
              })}
            </div>
          </div>  
          <div className={`relatedContentNav`} >
              <div>{navState.navButtons}</div>
              <div id="scrollLeft" className={leftNavigationState} onClick={handleScrollLeft}>&lt;</div>
              <div id="scrollRight" className={rightNavigationState} onClick={handleScrollRight}>&gt;</div>
          </div>
        </div>  
      )}

      {showCategoryGroupings() &&
          <div className="componentContainer">
            <h5 className={titleClass}>{title}</h5>
            <div className={blockClass} ref={cardsRef}>
              <div className='relatedContentContainer tagCards sectionCards' 
                   ref={scrollable}
                   style={{
                    transform: `translate(${scrollPosition}px,0)`,
                    minWidth: `${containerWidth}px`
                   }}>
                {sectionCardsFromPages(sectionGroupings)}
              </div>  
            </div>
            <div className={`relatedContentNav`} >
              <div>{navState.navButtons}</div>
              <div id="scrollLeft" className={leftNavigationState} onClick={handleScrollLeft}>&lt;</div>
              <div id="scrollRight" className={rightNavigationState} onClick={handleScrollRight}>&gt;</div>
          </div>
        </div>  
      }

    </div>)
  };

export default RelatedContentCardsView;


import React, { useEffect, useRef, useState }  from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { isBrowser, getImagesLoadingStatus, union, intersection, areSetsEqual, delay, symmetricDifference } from 'addons/custom-blocks/src/utilities';
import { getPages, getPendingImages, setPendingImages, registerPendingImages, registerImagesLoaded} from '../../actions';

import './page-loader.less';

const INCREMENT_CHECK_INTERVAL = 6;
const PageLoaderInsertion = (props) => {
    const ref = useRef();

    const [showClass, setShowClass] = useState("inactive");
    const [loadProgress, setLoadProgress] = useState(0);
    const [imageLoadStatus, setImageLoadStatus] = useState({
            urlsLeft: new Set(), 
            urlsLoaded: new Set(),
            contentRelevantImages: new Set(), 
            allLoaded: false});

    const dispatch = useDispatch();

    const defaultLocale = useSelector((state) => state.intl.defaultLocale);
    const locale = useSelector((state) => state.intl.locale);

    const url = useSelector((state) => {
      return state.router.location.pathname
    });

    const pendingImages = useSelector((state) => {
      return state.pendingImages
    });  

    // If there was a change of locale we immediatly trigger 
    // a getPages so that we get rid of any previously fetched
    // garment pages (as they would be in a different language)
    useEffect(() => {
      const localeToUse = (locale!=null && locale!=undefined && locale.trim().length>0) ? locale : defaultLocale;
      const selector = undefined; // we don't send a selector through, which will load all pages, as we want
      dispatch(getPages(selector, localeToUse));
    }, [locale]);
  


    useEffect(() => {
      const progress = imageLoadStatus.contentRelevantImages.size != 0 ? 100*imageLoadStatus.urlsLoaded.size/imageLoadStatus.contentRelevantImages.size : 0;
      // console.log(`[imageLoadStatus] imageLoadStatus (% ${progress}/${loadProgress}) status is`, imageLoadStatus);
      setLoadProgress(progress);
    }, [imageLoadStatus]);

    useEffect(( ) => {
      if (loadProgress % INCREMENT_CHECK_INTERVAL === 0) {
        calculateContentLoadProgress();
      }
      // console.log(`PageLoaderInsertion[loadProgress] url=${url}; loadProgress: ${loadProgress}`);
      setShowClass ((loadProgress>=100) ? "inactive" : "active")
    }, [loadProgress]);

    const calculateContentLoadProgress = () => {
        const images = Array.from(document.images);
        const loadStatus = getImagesLoadingStatus(document);

        const newUrlsLeft = intersection(loadStatus.urlsLeft, imageLoadStatus.urlsLeft);
        const newUrlsLoaded = union(loadStatus.urlsLoaded, imageLoadStatus.urlsLoaded);

        // console.log(`** >> PageLoaderInsertion calculateContentLoadProgress loadStatus, newUrlsLeft, newUrlsLoaded, imageLoadStatus`, loadStatus, newUrlsLeft, newUrlsLoaded, imageLoadStatus);

        setImageLoadStatus({
            urlsLeft: loadStatus.urlsLeft,
            urlsLoaded: loadStatus.urlsLoaded,
            contentRelevantImages: loadStatus.contentRelevantImages,
            allLoaded: loadStatus.allLoaded
        });

        // console.log(`areSetsEqual(loadStatus.contentRelevantImages, pendingImages.requiredUrls) [${pendingImages.progress}]`, areSetsEqual(loadStatus.contentRelevantImages, pendingImages.requiredUrls), loadStatus.contentRelevantImages, pendingImages);
        // console.log(`areSetsEqual(newUrlsLoaded, pendingImages.loadedUrls) [${pendingImages.progress}]`, areSetsEqual(newUrlsLoaded, pendingImages.loadedUrls), newUrlsLoaded, pendingImages);
        
        (!areSetsEqual(loadStatus.contentRelevantImages, pendingImages.requiredUrls)) && dispatch(registerPendingImages(url, Array.from(loadStatus.contentRelevantImages)));
        (!areSetsEqual(newUrlsLoaded, pendingImages.loadedUrls)) && dispatch(registerImagesLoaded(url, Array.from(newUrlsLoaded)));

    }

    useEffect(() => {
      // console.log(`PageLoaderInsertion  [url]  url=${url} loading: ${loading} loaded: ${loaded} error: ${error} showClass: ${showClass}`);
      // Set image load status to zero images pending
      // but also set allLoaded to false so that the loader is not hidden
      // and we have control as to the real meaning of there being zero images left to load.
      setImageLoadStatus({
        urlsLeft: new Set(), 
        urlsLoaded: new Set(),
        contentRelevantImages: new Set(), 
        allLoaded: false});
      dispatch(setPendingImages(url, []));
    }, [url]);

    useEffect(() => {
      // console.log(`PageLoaderInsertion [] url=${url} loadProgress: ${loadProgress}`);
      if(isBrowser()) {

          const imageStatus = getImagesLoadingStatus(document);
          dispatch(setPendingImages(url, Array.from(imageStatus.contentRelevantImages)));

          const loadedUrls = Array.from(imageStatus.urlsLoaded);
          dispatch(registerImagesLoaded(url, loadedUrls));

          // console.log(`PageLoaderInsertion [] url=${url} loadProgress: ${loadProgress} (initializing)`, imageStatus);

          const interval = setInterval(() => {
            calculateContentLoadProgress();

            getPendingImages(url);

          }, 1000);
      
          return () => {
            setLoadProgress(0);
            clearInterval(interval);
          }
      }
    }, []);

    return(<div ref={ref} id="page-loader" className={showClass} >
              <div id="load-bar" >
                <div id="load-bar-fill" data-progress={loadProgress} style={{width:`${loadProgress}%`}}></div>
              </div>
            </div>)

}


export default PageLoaderInsertion;
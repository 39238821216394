import React, { useRef }  from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import FadingImage from '../FadingImage/FadingImageView';
import { forceSameDomain } from '../utilities';

import './image-text-link.less';

const ImageTextLinkView = (props) => {
  const ref = useRef();
  const userSelectedLayout = props.data.layout != undefined ? props.data.layout : "";
  const componentClass = `image-text-link-block ${userSelectedLayout}`;

  let image = null;
  if (props.data.image != undefined) {
    try {
        image = forceSameDomain(props.data.image[0]["getURL"].replace(process.env.RAZZLE_API_PATH, "") + "/@@images/image");
    } catch (e) {
        image = "";
    }  
  }

  const title = (props.data.title != undefined && props.data.title.length > 0) ? props.data.title : "";
  const text = (props.data.text != undefined && props.data.text.length > 0) ? props.data.text : "";
  const cta = (props.data.cta != undefined) ? props.data.cta : "Learn more";
  const link = (props.data.target_url != undefined && props.data.target_url[0] != undefined) ? props.data.target_url[0].getURL.replace(process.env.RAZZLE_API_PATH, "") : null;
  const visible = (props.data.hidden==true) ? false : true;
  const editEnabled = (props.data.editEnabled==true) ? true : false;
  const textPosition = (props.data.text_right==true) ? 'right' : 'left';
  const flipClass = (props.data.flip_image==true) ? "flipped" : "original"

  const contentClassName = `image-text-link-block-content-container ${textPosition}`;
  
  return( 
  <div className={componentClass} ref={ref}>
    {(visible || editEnabled) && 
        <div className={contentClassName}>
            <div className='image-container'>
              <img src={image} className={flipClass}/>
            </div>

            <div className='text-container'>
              <h3 role="textbox" aria-multiline="true" className='lightStyle textLinkHeader'>{title}</h3>
              <div role="textbox" aria-multiline="true" className='lightStyle textLinkText'>{text}</div>
      
              {link!=null && 
                <div className='cta'>
                    <a href={link} >{cta} <FontAwesomeIcon icon={faArrowRight} /></a>
                </div>
              }
            </div>
        </div>  
    } 
  </div>)
};

export default ImageTextLinkView;